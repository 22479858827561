import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Select, Checkbox, Space, Tooltip, Table } from 'antd';
import { FilterFilled, CopyOutlined, LinkOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import CountUp from 'react-countup';
import { CSVLink } from "react-csv";
import { WalletFilterDropdown, SymbolFilterDropdown, DateFilterDropdown, NumericFilterDropdown } from './filter_function.jsx';
import { formatNumber, symbol_dict, formatLargeNumber } from '../utils/utils';

import "./../../css/all_metrics/all_metrics.css"
import "./../../css/all_metrics/antd_element.css"

import symbolMetricsCSV from './../../Data_csv/all_consolidate.csv';

import api_url from "./../../config/api_and_url.json"


const base_api = api_url.base_api

// JSON object mapping keys to labels
const headerMapping = {
    "wallet_address": "Wallet Address",
    "Symbol": "Symbol",
    "turnover": "Turnover",
    "PnL": "PnL",
    "number_of_trades": "Number of Trades",
    "avg_holding_time_min": "Avg Holding Time (min)",
    "profitable_trade_ratio": "Profitable Trade Ratio",
    "mm/tvl": "MM/TVL Ratio",
    "max_dd": "Max Drawdown",
    "last_trade_time": "Last Trade Time",
    "first_trade_time": "First Trade Time",
    "percent_long_trades": "Long Trades (%)",
    "long_trade_profitability": "Long Trade Profitability",
    "short_trade_profitability": "Short Trade Profitability",
    "traded_days": "Traded Days",
    "last_15_days_pnl": "Last 15 Days PnL",
    "dd_age": "Drawdown Age"
};

// Array of objects with key-label pairs
const headerMappingArray = [
    { key: "wallet_address", label: "Wallet Address" },
    { key: "Symbol", label: "Symbol" },
    { key: "turnover", label: "Turnover" },
    { key: "PnL", label: "PnL" },
    { key: "number_of_trades", label: "Number of Trades" },
    { key: "avg_holding_time_min", label: "Avg Holding Time (min)" },
    { key: "profitable_trade_ratio", label: "Profitable Trade Ratio" },
    { key: "mm/tvl", label: "MM/TVL Ratio" },
    { key: "max_dd", label: "Max Drawdown" },
    { key: "last_trade_time", label: "Last Trade Time" },
    { key: "first_trade_time", label: "First Trade Time" },
    { key: "percent_long_trades", label: "Long Trades (%)" },
    { key: "long_trade_profitability", label: "Long Trade Profitability" },
    { key: "short_trade_profitability", label: "Short Trade Profitability" },
    { key: "traded_days", label: "Traded Days" },
    { key: "last_15_days_pnl", label: "Last 15 Days PnL" },
    { key: "dd_age", label: "Drawdown Age" }
];

// Define default columns to show
const defaultColumns = [
    'wallet_address',
    'Symbol',
    'avg_holding_time_min',
    'profitable_trade_ratio',
    'PnL',
    'last_15_days_pnl',
    'number_of_trades',
    'turnover',
    'traded_days'
];

// const DataFormat = "DD/MM/YY HH:mm"
const DataFormat = "YYYY/MM/DD HH:mm"
// const DataFormat = "YYYY/MM/DD HH:mm:ss"

const SymbolWiseMetricsTable = () => {
    const [data, setData] = useState([]);
    const [showingData, setShowingData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsOption, setColumnsOption] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [symbols, setSymbols] = useState([]);
    const [walletColumn, setWalletColumn] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [filterParams, setFilterParams] = useState({});

    const location = useLocation();
    const navigate = useNavigate();

    const fetchTokenNames = async (symbols) => {
        try {
            const response = await fetch(`${base_api}/get_token_names`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ references: symbols }),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch token names. Status: ${response.status}`);
            }

            const result = await response.json();
            return result.data;
        } catch (error) {
            console.error('Error fetching token names:', error);
            throw error;
        }
    };

    useEffect(() => {
        const fetchCSV = async () => {
            try {
                /* version 2 */
                const response = await fetch(symbolMetricsCSV);
                const reader = response.body.getReader();
                const decoder = new TextDecoder('utf-8');
                let csv = '';

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    csv += decoder.decode(value, { stream: true });
                }

                // Check if the CSV is empty
                if (csv.trim() === '') {
                    console.log('The CSV file is empty.');
                    setData([]);
                    setShowingData([]);
                    return;
                }

                Papa.parse(csv, {
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true, // Skip empty lines
                    complete: async (results) => {

                        // Check if there's data beyond headers
                        if (results.data.length === 0) {
                            console.log('The CSV file contains only headers.');
                            setData([]);
                            setShowingData([]);
                            return;
                        }
                        const parsedData = results.data.map(item => ({
                            ...item,
                            last_trade_time: moment(item.last_trade_time, DataFormat).toDate(),
                            first_trade_time: moment(item.first_trade_time, DataFormat).toDate()
                        }));

                        // Assuming parsedData contains your CSV data
                        const uniqueWalletSymbolMap = new Map();

                        parsedData.forEach(item => {
                            const key = `${item.wallet_address}-${item.Symbol}`;
                            uniqueWalletSymbolMap.set(key, item);
                        });

                        const uniqueData = Array.from(uniqueWalletSymbolMap.values());

                        // Extract unique symbols for the API call
                        const uniqueSymbols = [...new Set(uniqueData.map(item => item.Symbol))].filter(Boolean);

                        // Fetch actual token names
                        const tokenNameMap = await fetchTokenNames(uniqueSymbols);

                        const data_refactor = uniqueData.map(item => ({
                            ...item,
                            Symbol: tokenNameMap[item.Symbol] ||
                                (symbol_dict[item.Symbol] ? symbol_dict[item.Symbol].split("/")[0] : item.Symbol.split("/")[0])
                        }));

                        setData(data_refactor);
                        setShowingData(data_refactor)

                        // Extract unique symbols
                        // Rest of your existing code for columns setup...
                        const uniqueSymbolsAfterReplacement = [...new Set(data_refactor.map(item => item.Symbol))].filter(Boolean);

                        const csvColumns = Object.keys(data_refactor[0])
                            .map((key) => {
                                let column = {
                                    title: headerMapping[key] || key,
                                    dataIndex: key,
                                    key: key,
                                    align: 'center',
                                    width: 170,
                                    fixed: key === 'wallet_address' ? 'left' : false,
                                    sorter: (a, b) => {
                                        if (typeof a[key] === 'number' && typeof b[key] === 'number') {
                                            return a[key] - b[key];
                                        } else if (key === 'last_trade_time' || key === 'first_trade_time') {
                                            return a[key] - b[key];
                                        } else {
                                            return (a[key] || '').localeCompare(b[key] || '');
                                        }
                                    },
                                    sortDirections: ['descend', 'ascend'],
                                    render: (text, record) => {
                                        if (key === 'wallet_address') {
                                            return (
                                                <Space>
                                                    <Tooltip title={text}>
                                                        {shortenAddress(text)}
                                                    </Tooltip>
                                                    <Tooltip title="Copy to clipboard">
                                                        <CopyOutlined onClick={() => copyToClipboard(text)} style={{ cursor: 'pointer' }} />
                                                    </Tooltip>
                                                    <Tooltip title="View portfolio">
                                                        <LinkOutlined onClick={() => window.open(`/portfolio/?address=${text}`, '_blank')} style={{ cursor: 'pointer' }} />
                                                    </Tooltip>
                                                </Space>
                                            );
                                        }
                                        if (key === 'Symbol') {
                                            return text; // Return Symbol as-is
                                        }

                                        if (key === 'last_trade_time' || key === 'first_trade_time') {
                                            return moment(text).format(DataFormat);
                                        }
                                        const value = parseFloat(text);
                                        if (!isNaN(value)) {
                                            if (key === 'turnover' || key === 'PnL' || key === 'last_15_days_pnl' || key === 'max_dd') {
                                                return formatLargeNumber(value)
                                            }
                                            return formatNumber(value)
                                        }
                                        return text;
                                    },
                                };

                                // Custom filter for wallet address
                                if (key === 'wallet_address') {
                                    column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                        <WalletFilterDropdown
                                            setSelectedKeys={setSelectedKeys}
                                            selectedKeys={selectedKeys}
                                            confirm={confirm}
                                            clearFilters={clearFilters}
                                        />
                                    );
                                    column.onFilter = (value, record) => record.wallet_address.toLowerCase().includes(value.toLowerCase());
                                }

                                // Custom filter for symbol
                                else if (key === 'Symbol') {
                                    column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                        <SymbolFilterDropdown
                                            symbols={uniqueSymbolsAfterReplacement}
                                            setSelectedKeys={setSelectedKeys}
                                            selectedKeys={selectedKeys}
                                            confirm={confirm}
                                            clearFilters={clearFilters}
                                        />
                                    );
                                    column.onFilter = (value, record) => record.Symbol === value;
                                }

                                // Custom filter for date columns
                                else if (key === 'first_trade_time' || key === 'last_trade_time') {
                                    column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                        <DateFilterDropdown
                                            setSelectedKeys={setSelectedKeys}
                                            selectedKeys={selectedKeys}
                                            confirm={confirm}
                                            clearFilters={clearFilters}
                                        />
                                    );
                                    column.onFilter = (value, record) => {
                                        const [comparison, dateString] = value.split(',');
                                        const recordDate = moment(record[key]);
                                        const filterDate = moment(dateString, "YYYY-MM-DD HH:mm:ss");

                                        switch (comparison) {
                                            case 'before':
                                                return recordDate.isBefore(filterDate);
                                            case 'after':
                                                return recordDate.isAfter(filterDate);
                                            case 'on':
                                                return recordDate.isSame(filterDate, 'day');
                                            default:
                                                return true;
                                        }
                                    };
                                }

                                // Default numeric filter for other columns
                                else {
                                    column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                        <NumericFilterDropdown
                                            setSelectedKeys={setSelectedKeys}
                                            selectedKeys={selectedKeys}
                                            confirm={confirm}
                                            clearFilters={clearFilters}
                                        />
                                    );
                                    column.onFilter = (value, record) => {
                                        const [comparison, filterValue] = value.split(',');
                                        const recordValue = parseFloat(record[key]);
                                        const filterNumber = parseFloat(filterValue);

                                        switch (comparison) {
                                            case 'gt':
                                                return recordValue > filterNumber;
                                            case 'lt':
                                                return recordValue < filterNumber;
                                            case 'eq':
                                                return recordValue === filterNumber;
                                            case 'lte':
                                                return recordValue <= filterNumber;
                                            default:
                                                return true;
                                        }
                                    };
                                }

                                column.filterIcon = filtered => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />;

                                return column;
                            });
                        const walletCol = csvColumns.find(col => col.key === 'wallet_address');
                        const otherCols = csvColumns.filter(col => col.key !== 'wallet_address');

                        setWalletColumn(walletCol);
                        setColumnsOption(otherCols)
                        setColumns(csvColumns);

                        // Set selected columns to default columns
                        setSelectedColumns(defaultColumns);

                        const params = queryString.parse(location.search);
                        if (params.columns) {
                            const urlColumns = params.columns.split(',');
                            setSelectedColumns(urlColumns);
                        } else {
                            setSelectedColumns(defaultColumns);
                        }
                        if (params.filters) {
                            const urlFilters = JSON.parse(params.filters);
                            applyFiltersFromURL(data_refactor, urlFilters, csvColumns);
                        }
                    },
                    error: (error) => {
                        setError('Error parsing CSV: ' + error.message);
                    }
                });
            } catch (error) {
                setError('Error reading CSV file: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCSV();
    }, [location.search]);

    const applyFiltersFromURL = (data, urlFilters, columns) => {
        let filteredData = [...data];
        const newFilters = {};

        Object.entries(urlFilters).forEach(([key, value]) => {
            const column = columns.find(col => col.key === key);
            if (column && column.onFilter) {
                filteredData = filteredData.filter(item => column.onFilter(value, item));
                newFilters[key] = value;
            }
        });

        setFilterParams(newFilters);
        setShowingData(filteredData);
    };

    useEffect(() => {
        const params = queryString.parse(location.search);
        if (params.sort) {
            const [field, order] = params.sort.split(',');
            setSortField(field);
            setSortOrder(order);
        }
        if (params.filters) {
            setFilterParams(JSON.parse(params.filters));
        }
        if (params.columns) {
            const urlColumns = params.columns.split(',');
            setSelectedColumns(urlColumns);
        }
    }, [location.search]);

    const shortenAddress = (address) => {
        return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            // You can add a notification here if you want to inform the user that the text was copied
            console.log('Copied to clipboard');
        });
    };

    const handleColumnChange = (columnKey, isAdding) => {
        const newSelectedColumns = isAdding
            ? [...selectedColumns, columnKey]
            : selectedColumns.filter(key => key !== columnKey);
        setSelectedColumns(newSelectedColumns);
        updateQueryParams(sortField, sortOrder, filterParams, newSelectedColumns);
    };

    const visibleColumns = walletColumn
        ? [walletColumn, ...columnsOption.filter(col => selectedColumns.includes(col.key))]
        : columns.filter(col => selectedColumns.includes(col.key));

    visibleColumns.forEach(column => {
        if (column.key === sortField) {
            column.defaultSortOrder = sortOrder;
        }
        if (filterParams[column.key]) {
            column.filteredValue = [filterParams[column.key]];
        }
    });
    // Sort the visible columns according to the defaultColumns order
    visibleColumns.sort((a, b) => {
        const indexA = defaultColumns.indexOf(a.key);
        const indexB = defaultColumns.indexOf(b.key);
        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
    });

    const handleTableChange = (pagination, filters, sorter) => {
        console.log('Table parameters:', pagination, filters, sorter);

        console.log('data', data.length);

        const newSortField = sorter.order ? sorter.field : null;
        const newSortOrder = sorter.order ? sorter.order : null;
        const newFilters = {};

        Object.keys(filters).forEach(key => {
            if (filters[key] && filters[key].length > 0) {
                newFilters[key] = filters[key][0];
            }
        });

        setSortField(newSortField);
        setSortOrder(newSortOrder);
        setFilterParams(newFilters);

        updateQueryParams(newSortField, newSortOrder, newFilters, selectedColumns);

        // Update the data based on the current filters
        let filteredData = [...data];
        Object.keys(newFilters).forEach(key => {
            const column = columns.find(col => col.key === key);
            if (column && column.onFilter) {
                filteredData = filteredData.filter(item => column.onFilter(newFilters[key], item));
            }
        });

        setShowingData(filteredData);
    };

    const updateQueryParams = (newSortField, newSortOrder, newFilters, newSelectedColumns) => {
        const params = {};
        if (newSortField && newSortOrder) {
            params.sort = `${newSortField},${newSortOrder}`;
        }
        if (Object.keys(newFilters).length > 0) {
            params.filters = JSON.stringify(newFilters);
        }
        if (newSelectedColumns.length > 0) {
            params.columns = newSelectedColumns.join(',');
        }
        navigate(`?${queryString.stringify(params)}`, { replace: true });
    };

    if (loading) {
        return <div className="metrics_loader_card ">Loading data...</div>;
    }

    if (error) {
        return <div className="metrics_error_card ">Error: {error}</div>;
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;

    return (
        <>
            <div className="dashBoard_head">
                <div className="wallet_note">
                    <h4>
                        We show only realized PnL with assumption of 2 bps fee per trade. Analytics are based on the last 10,000 transactions or all if fewer.
                    </h4>
                </div>
            </div>
            <br />
            <div className="all_metrics_main_grid">
                <div className='all_metrics_main_card all_metrics_main_card_content'>
                    <div className="all_metrics_content_heading">Total Entries</div>
                    <div className="all_metrics_content_value"><CountUp end={data.length} duration={2.5} formattingFn={(value) => formatNumber(value)} /></div>
                </div>
                <div className='all_metrics_main_card all_metrics_main_card_content'>
                    <div className="all_metrics_content_heading">Total Entries Shown</div>
                    <div className="all_metrics_content_value"><CountUp end={showingData.length} duration={2.5} formattingFn={(value) => formatNumber(value)} /></div>
                </div>
                <div className='all_metrics_main_card all_metrics_main_card_content'>
                    <div className="all_metrics_content_heading">Select additional columns to display</div>
                    <Select
                        mode="multiple"
                        placeholder="Select columns to display"
                        value={selectedColumns}
                        onChange={(values) => {
                            setSelectedColumns(values);
                            updateQueryParams(sortField, sortOrder, filterParams, values);
                        }}
                        dropdownRender={menu => (
                            <div>
                                {columnsOption.map(col => (
                                    <div key={col.key} style={{ padding: '8px 12px', borderBottom: '1px solid var(--border-color)' }}>
                                        <Checkbox
                                            checked={selectedColumns.includes(col.key)}
                                            onChange={(e) => handleColumnChange(col.key, e.target.checked)}
                                        >
                                            {col.title}
                                        </Checkbox>
                                    </div>
                                ))}
                            </div>
                        )}
                        maxTagCount={0}
                        maxTagPlaceholder={() => `${selectedColumns.length} out of ${columnsOption.length + 1} columns selected`}
                    />
                </div>
                <div className='all_metrics_main_card all_metrics_main_card_content'>
                    <div className="all_metrics_content_heading">Export to CSV</div>
                    <div className="all_metrics_content_value">
                        <CSVLink
                            data={showingData}
                            headers={headerMappingArray}
                            filename={`Hyperliquid_all_metrics_${today}.csv`}
                        >
                            <DownloadOutlined style={{ cursor: 'pointer', color: "var(--primary-color)", fontSize: "43px" }} />
                        </CSVLink>
                    </div>
                </div>
            </div >
            <div className="table_note">
                Please scroll right to see all columns
            </div>
            <Table
                columns={visibleColumns}
                dataSource={showingData}
                tableLayout='fixed'
                scroll={{ x: '100vw', y: '70vh' }}
                onChange={handleTableChange}
                sortField={sortField}
                sortOrder={sortOrder}
            />
        </>
    );
};

export default SymbolWiseMetricsTable;
import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { ChevronUp, ChevronDown, Copy, Check } from 'lucide-react';
import { formatNumber, formatLargeNumber } from '../utils/utils';
import './../../css/validators_dasboard/validator_dashboard.css';

const ValidatorDashboard = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [blockHeight, setBlockHeight] = useState("316,904,018");
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: 'asc'
    });
    const [copiedAddress, setCopiedAddress] = useState(null);
    const [tooltip, setTooltip] = useState({ show: false, text: '', x: 0, y: 0 });

    // Dynamic chart height based on screen size
    const [chartHeight, setChartHeight] = useState(window.innerWidth > 768 ? 200 : 150);

    // Define semi-transparent colors
    const COLORS = {
        active: 'rgba(77, 129, 234, 0.7)',
        jailed: 'rgba(204, 51, 51, 0.7)'
    };

    // Tooltip functions
    const showTooltip = (e, text) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setTooltip({
            show: true,
            text: text,
            x: rect.left + window.scrollX + rect.width / 2,
            y: rect.top + window.scrollY - 30
        });
    };

    const hideTooltip = () => {
        setTooltip({ show: false, text: '', x: 0, y: 0 });
    };

    const handleCopy = async (address) => {
        try {
            await navigator.clipboard.writeText(address);
            setCopiedAddress(address);
            setTimeout(() => setCopiedAddress(null), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    const fetchBlockHeight = async () => {
        try {
            const response = await fetch('https://prometheus.nirmaan.ai/api/v1/query?query=hl_block_height');
            const result = await response.json();
            if (result.status === "success" && result.data.result[0]?.value[1]) {
                setBlockHeight(parseInt(result.data.result[0].value[1]).toLocaleString());
            }
        } catch (error) {
            console.error('Error fetching block height:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await fetch('https://api.hyperliquid-testnet.xyz/info', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'validatorSummaries'
                })
            });
            let result = await response.json();

            // const jailedValidators = result.filter(v => v.isJailed);
            // const activeValidators = result.filter(v => !v.isJailed);

            // jailedValidators.sort((a, b) => b.stake - a.stake);
            // activeValidators.sort((a, b) => b.stake - a.stake);
            // setData([...jailedValidators, ...activeValidators]);

            const sort_by_name = result.sort((a, b) => a.name.localeCompare(b.name));
            setData([...sort_by_name]);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    // Handle resize to update chart height dynamically
    useEffect(() => {
        const updateChartHeight = () => {
            setChartHeight(window.innerWidth > 768 ? 200 : 150);
        };

        window.addEventListener("resize", updateChartHeight);
        return () => window.removeEventListener("resize", updateChartHeight);
    }, []);

    useEffect(() => {
        fetchData();
        // fetchBlockHeight();
        const dataInterval = setInterval(fetchData, 15000);
        // const heightInterval = setInterval(fetchBlockHeight, 15000);
        return () => {
            clearInterval(dataInterval);
            // clearInterval(heightInterval);
        };
    }, []);

    const sortData = (data, sortConfig) => {
        if (!sortConfig.key) return data;

        return [...data].sort((a, b) => {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            if (sortConfig.key === 'stake') {
                aValue = parseFloat(aValue);
                bValue = parseFloat(bValue);
            }

            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (columnName) => {
        const tooltipText = sortConfig.key === columnName
            ? `Sorted ${sortConfig.direction === 'asc' ? 'ascending' : 'descending'}`
            : 'Click to sort';

        return (
            <div
                onMouseEnter={(e) => showTooltip(e, tooltipText)}
                onMouseLeave={hideTooltip}
                className="vd_sort_icon_container"
            >
                {sortConfig.key !== columnName ? (
                    <ChevronUp size={14} style={{ opacity: 0.3 }} />
                ) : (
                    sortConfig.direction === 'asc' ? (
                        <ChevronUp size={14} style={{ color: 'var(--primary-color)' }} />
                    ) : (
                        <ChevronDown size={14} style={{ color: 'var(--primary-color)' }} />
                    )
                )}
            </div>
        );
    };

    const formatStake = (stake) => {
        return formatLargeNumber(stake / 1e8);
    };

    const formatAddress = (address) => {
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };

    const pieData = [
        {
            name: 'Active',
            value: data.filter(v => !v.isJailed).length,
            color: COLORS.active
        },
        {
            name: 'Jailed',
            value: data.filter(v => v.isJailed).length,
            color: COLORS.jailed
        }
    ];

    const CopyButton = ({ address }) => {
        const tooltipText = copiedAddress === address ? 'Copied!' : 'Copy address';

        return (
            <button
                onClick={() => handleCopy(address)}
                onMouseEnter={(e) => showTooltip(e, tooltipText)}
                onMouseLeave={hideTooltip}
                className={`vd_copy_button ${copiedAddress === address ? 'copied' : ''}`}
            >
                {copiedAddress === address ? (
                    <Check size={16} />
                ) : (
                    <Copy size={16} />
                )}
            </button>
        );
    };

    const sortedData = sortData(data, sortConfig);

    if (loading) return <div className="validator_metrics_loader_card">Loading data...</div>;

    return (
        <div className="vd_dashboard_container">
            {tooltip.show && (
                <div
                    className="vd_tooltip"
                    style={{
                        left: `${tooltip.x}px`,
                        top: `${tooltip.y}px`,
                    }}
                >
                    {tooltip.text}
                </div>
            )}

            <div className="vd_dashboard_grid">
                <div className="vd_table_section">
                    <div className="vd_table_container">
                        <table className="vd_validator_table">
                            <colgroup>
                                <col className="name-col" />
                                <col className="stake-col" />
                                <col className="address-col" />
                                <col className="blocks-col" />
                                <col className="status-col" />
                            </colgroup>
                            <thead className="vd_table_header">
                                <tr>
                                    {[
                                        { key: 'name', label: 'Name' },
                                        { key: 'stake', label: 'Stake' },
                                        { key: 'validator', label: 'Validator Address' },
                                        { key: 'nRecentBlocks', label: 'Recent blocks' },
                                        { key: 'isJailed', label: 'Status' }
                                    ].map((column) => (
                                        <th
                                            key={column.key}
                                            onClick={() => requestSort(column.key)}
                                            className="vd_header_cell"
                                            style={{
                                                backgroundColor: sortConfig.key === column.key
                                                    ? 'rgba(77, 129, 234, 0.1)'
                                                    : 'transparent'
                                            }}
                                        >
                                            <div className="vd_sort_icon_container">
                                                {column.label}
                                                {getSortIcon(column.key)}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map((item) => (
                                    <tr key={item.validator}>
                                        <td className="vd_body_cell">{item.name}</td>
                                        <td className="vd_body_cell">{formatStake(item.stake)}</td>
                                        <td className="vd_address_cell">
                                            {formatAddress(item.validator)}
                                            <CopyButton address={item.validator} />
                                        </td>
                                        <td className="vd_body_cell">{item.nRecentBlocks}</td>
                                        <td
                                            className="vd_body_cell"
                                            style={{
                                                color: item.isJailed ? 'var(--negative-heighlight-color)' : 'var(--positive-heighlight-color)'
                                            }}
                                        >
                                            {item.isJailed ? 'Jailed' : 'Active'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="vd_chart_section">
                    <div className="vd_block_height">
                        <div className="vd_block_height_label">
                            Current Block Height
                        </div>
                        <div className="vd_block_height_value">
                            {blockHeight || 'Loading...'}
                        </div>
                    </div>

                    <div className="vd_chart_container">
                        <ResponsiveContainer width="100%" height={chartHeight}>
                            <PieChart>
                                <Pie
                                    data={pieData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="45%"
                                    outerRadius={window.innerWidth > 768 ? "80%" : "60%"}
                                    innerRadius="40%"
                                >
                                    {pieData.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.name === 'Active' ? COLORS.active : COLORS.jailed}
                                        />
                                    ))}
                                </Pie>
                                <Legend
                                    verticalAlign="bottom"
                                    align="center"
                                    formatter={(value, entry) => {
                                        if (value === 'Active') return `Active: ${entry.payload.value}`;
                                        return `Jailed: ${entry.payload.value}`;
                                    }}
                                    wrapperStyle={{
                                        fontSize: '12px',
                                        paddingTop: '10px',
                                        paddingBottom: '5px'
                                    }}
                                />
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>

                    <div className="vd_total_validators">
                        Total Validators: {data.length}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ValidatorDashboard;

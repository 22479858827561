import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid
} from 'recharts';

const ProposalRateChart = () => {
    const [chartData, setChartData] = useState([]);
    const [validatorNames, setValidatorNames] = useState({});
    const [selectedValidators, setSelectedValidators] = useState(new Set());
    const [showAllValidators, setShowAllValidators] = useState(true);
    const [loading, setLoading] = useState(true);
    const [validatorStats, setValidatorStats] = useState({});
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 750);
    const [showChart, setShowChart] = useState(true);

    useEffect(() => {
        const handleResize = () => setIsMobileView(window.innerWidth <= 750);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch validator names
    const fetchValidatorNames = async () => {
        try {
            const response = await fetch('https://api.hyperliquid-testnet.xyz/info', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ type: "validatorSummaries" })
            });
            const data = await response.json();
            const names = {};
            data.forEach(validator => {
                names[validator.validator.toLowerCase()] = validator.name || validator.validator.slice(0, 8);
            });
            setValidatorNames(names);
        } catch (error) {
            console.error('Error fetching validator names:', error);
        }
    };

    const processPrometheusData = useCallback((results) => {
        const timeseriesData = [];

        if (results.length > 0) {
            const firstSeries = results[0].values;
            firstSeries.forEach((point, index) => {
                const timestamp = new Date(point[0] * 1000);
                const timePoint = {
                    timestamp: timestamp.toLocaleTimeString(),
                    date: timestamp.toLocaleDateString(),
                };

                results.forEach(series => {
                    const validatorAddress = series.metric.proposer.toLowerCase();
                    const validatorName = validatorNames[validatorAddress] || validatorAddress.slice(0, 8);
                    if (series.values[index]) {
                        let value = parseFloat(series.values[index][1]);
                        if (isNaN(value) || !isFinite(value) || value > 2) {
                            value = 0;
                        }
                        timePoint[validatorName] = value;
                    } else {
                        timePoint[validatorName] = 0;
                    }
                });

                timeseriesData.push(timePoint);
            });
        }

        return timeseriesData;
    }, [validatorNames]);

    const calculateValidatorStats = useCallback((data) => {
        const stats = {};
        Object.values(validatorNames).forEach(name => {
            const values = data.map(point => point[name] || 0);
            const maxValue = Math.max(...values);
            const hasActivity = maxValue > 0;
            stats[name] = {
                maxValue,
                hasActivity
            };
        });
        setValidatorStats(stats);
    }, [validatorNames]);

    const fetchPrometheusData = useCallback(async () => {
        setLoading(true);
        const end = Math.floor(Date.now() / 1000);
        const start = end - (12 * 60 * 60); // 12 hours ago

        try {
            const response = await fetch(`https://prometheus.nirmaan.ai/api/v1/query_range?query=rate(hl_proposer_count_total[12h])&start=${start}&end=${end}&step=300`);
            const data = await response.json();

            if (data.status === 'success' && data.data.result) {
                const processedData = processPrometheusData(data.data.result);
                setChartData(processedData);
                calculateValidatorStats(processedData);
            }
        } catch (error) {
            console.error('Error fetching Prometheus data:', error);
        } finally {
            setLoading(false);
        }
    }, [processPrometheusData, calculateValidatorStats]);

    useEffect(() => {
        fetchValidatorNames();
    }, []);

    useEffect(() => {
        if (Object.keys(validatorNames).length > 0) {
            fetchPrometheusData();
            const interval = setInterval(fetchPrometheusData, 60000); // Refresh every minute
            return () => clearInterval(interval);
        }
    }, [validatorNames, fetchPrometheusData]);

    const yAxisDomain = useMemo(() => {
        if (!chartData.length) return [0, 1];

        let maxValue = 0;
        chartData.forEach(point => {
            Object.entries(point).forEach(([key, value]) => {
                if (
                    key !== 'timestamp' &&
                    key !== 'date' &&
                    (showAllValidators || selectedValidators.has(key))
                ) {
                    if (value <= 2) {
                        maxValue = Math.max(maxValue, value);
                    }
                }
            });
        });

        return maxValue < 0.1 ? [0, 0.1] : [0, Math.ceil(maxValue * 10) / 10];
    }, [chartData, selectedValidators, showAllValidators]);

    const getValidatorColor = (index) => {
        const colors = [
            '#4d81ea', '#FF7F50', '#33cc33', '#cc3333', '#FEEA75',
            '#9370DB', '#20B2AA', '#FF69B4', '#00CED1', '#FFD700'
        ];
        return colors[index % colors.length];
    };

    const toggleValidator = (validatorName) => {
        setSelectedValidators(prev => {
            const newSelected = new Set(prev);
            if (prev.has(validatorName)) {
                newSelected.delete(validatorName);
            } else {
                newSelected.add(validatorName);
            }
            return newSelected;
        });
        setShowAllValidators(false);
    };

    const toggleAllValidators = () => {
        if (!showAllValidators) {
            setSelectedValidators(new Set());
            setShowAllValidators(true);
        }
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const sortedPayload = [...payload].sort((a, b) => b.value - a.value);
            const midPoint = Math.ceil(sortedPayload.length / 2);
            const firstColumn = sortedPayload.slice(0, midPoint);
            const secondColumn = sortedPayload.slice(midPoint);

            return (
                <div style={{
                    backgroundColor: 'var(--card-background-color)',
                    border: '1px solid var(--border-color)',
                    borderRadius: '4px',
                    padding: '8px',
                    fontSize: '11px',
                    lineHeight: '1.2',
                    maxWidth: isMobileView ? '300px' : '400px', // Adjust width for mobile
                    zIndex: 1000,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    left: isMobileView ? '10%' : 'auto', // Center on mobile view
                    right: isMobileView ? '10%' : 'auto' // Center on mobile view
                }}>
                    <p style={{
                        margin: '0 0 4px 0',
                        color: 'var(--text-color)',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid var(--border-color)',
                        paddingBottom: '4px',
                        width: '100%'
                    }}>
                        {label}
                    </p>
                    <div style={{
                        display: 'flex',
                        gap: '16px',
                        justifyContent: 'space-between'
                    }}>
                        {/* First Column */}
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr auto',
                            gap: '2px 8px',
                            minWidth: '140px'
                        }}>
                            {firstColumn.map((entry, index) => (
                                <React.Fragment key={`col1-${index}`}>
                                    <span style={{
                                        color: entry.color,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100px'
                                    }}>
                                        {entry.name}
                                    </span>
                                    <span style={{
                                        color: entry.color,
                                        textAlign: 'right',
                                        fontWeight: '500'
                                    }}>
                                        {entry.value.toFixed(3)}
                                    </span>
                                </React.Fragment>
                            ))}
                        </div>

                        {/* Second Column */}
                        {secondColumn.length > 0 && (
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr auto',
                                gap: '2px 8px',
                                minWidth: '140px',
                                borderLeft: '1px solid var(--border-color)',
                                paddingLeft: '16px'
                            }}>
                                {secondColumn.map((entry, index) => (
                                    <React.Fragment key={`col2-${index}`}>
                                        <span style={{
                                            color: entry.color,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100px'
                                        }}>
                                            {entry.name}
                                        </span>
                                        <span style={{
                                            color: entry.color,
                                            textAlign: 'right',
                                            fontWeight: '500'
                                        }}>
                                            {entry.value.toFixed(3)}
                                        </span>
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        return null;
    };


    const ValidatorList = () => (
        <div style={{
            width: isMobileView ? '100%' : '200px',
            maxHeight: isMobileView ? 'auto' : '500px',
            overflowY: 'auto',
            borderLeft: '1px solid var(--border-color)',
            padding: '10px',
        }}>
            <div
                style={{
                    marginBottom: '10px',
                    padding: '8px',
                    cursor: 'pointer',
                    backgroundColor: showAllValidators ? 'var(--primary-color)' : 'transparent',
                    borderRadius: '4px',
                    transition: 'background-color 0.2s'
                }}
                onClick={toggleAllValidators}
            >
                Select All
            </div>
            {Object.values(validatorNames).map((name, index) => (
                <div
                    key={name}
                    onClick={() => toggleValidator(name)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                        cursor: 'pointer',
                        backgroundColor: selectedValidators.has(name) ? 'var(--primary-color)' : 'transparent',
                        borderRadius: '4px',
                        marginBottom: '4px',
                        transition: 'background-color 0.2s',
                        opacity: validatorStats[name]?.hasActivity ? 1 : 0.6,
                    }}
                >
                    <span
                        style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: getValidatorColor(index),
                            marginRight: '8px',
                            borderRadius: '2px'
                        }}
                    />
                    <div style={{ fontSize: '14px' }}>
                        <div>{name}</div>
                        {!validatorStats[name]?.hasActivity && (
                            <div style={{
                                fontSize: '10px',
                                color: 'var(--text-color-heading)'
                            }}>
                                No recent proposals
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div style={{ display: isMobileView ? 'block' : 'flex', flexDirection: 'row', gap: '20px' }}>
            <div style={{
                border: '1px solid var(--border-color)',
                borderRadius: '8px',
                padding: '20px',
                flex: 1
            }}>
                <div style={{
                    marginBottom: '10px',
                    textAlign: 'center'
                }}>
                    <h3 style={{
                        color: 'var(--text-color)',
                        fontSize: '16px',
                        fontWeight: 'normal',
                        marginBottom: '5px'
                    }}>
                        Proposals Per Second (12H Window)
                    </h3>
                    <p style={{
                        color: 'var(--text-color-heading)',
                        fontSize: '12px',
                        margin: 0
                    }}>
                        Click on validator names to show/hide individual data • Y-axis adjusts automatically to selected validators
                    </p>
                </div>

                {isMobileView && (
                    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                        <button
                            style={{
                                backgroundColor: 'var(--primary-color)',
                                color: 'white',
                                padding: '8px 16px',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                            onClick={() => setShowChart(!showChart)}
                        >
                            {showChart ? 'Show Validators' : 'Show Chart'}
                        </button>
                    </div>
                )}

                <div style={{
                    height: isMobileView ? '300px' : '400px',
                    display: isMobileView && !showChart ? 'none' : 'flex'
                }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            data={chartData}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: isMobileView ? 50 : 50
                            }}
                        >
                            <CartesianGrid
                                strokeDasharray="3 3"
                                vertical={false}
                                stroke="var(--border-color)"
                            />
                            <XAxis
                                dataKey="timestamp"
                                tick={{ fill: 'var(--text-color)', fontSize: isMobileView ? 10 : 12 }}
                                angle={isMobileView ? -30 : -45} // Slightly rotate labels on mobile
                                textAnchor="end"
                                height={isMobileView ? 40 : 60} // Increase height for better spacing
                                interval={isMobileView ? Math.floor(chartData.length / 10) : Math.floor(chartData.length / 10)} // Further reduce labels on mobile
                            />

                            <YAxis
                                tick={{ fill: 'var(--text-color)', fontSize: 12 }}
                                axisLine={{ stroke: 'var(--border-color)' }}
                                domain={yAxisDomain}
                                tickCount={6}
                                label={{
                                    value: 'Proposals/sec',
                                    angle: -90,
                                    position: 'insideLeft',
                                    fill: 'var(--text-color)',
                                    offset: -10
                                }}
                            />
                            <Tooltip
                                content={<CustomTooltip />}
                                cursor={{ stroke: 'var(--border-color)', strokeWidth: 1 }}
                            />
                            {Object.values(validatorNames).map((name, index) => (
                                (showAllValidators || selectedValidators.has(name)) && (
                                    <Line
                                        key={name}
                                        type="monotone"
                                        dataKey={name}
                                        stroke={getValidatorColor(index)}
                                        dot={false}
                                        strokeWidth={2}
                                    />
                                )
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
            {!isMobileView || !showChart ? <ValidatorList /> : null}
        </div>
    );
};

export default ProposalRateChart;

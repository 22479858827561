import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Dashboard from './dashboard';
import "./../../css/dashboard/portfolio.css"
import { Loader2 } from "lucide-react"
import api_url from "./../../config/api_and_url.json"


const base_api = api_url.base_api
const API_URL = base_api + '/trade_history';

const Portfolio = () => {
    const [walletData, setWalletData] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const addressParam = searchParams.get('address');
        if (addressParam) {
            setWalletAddress(addressParam);
            fetchWalletData(addressParam);
        }
    }, [location]);

    const extractUniqueSymbols = (walletData) => {
        if (!walletData) return [];

        const symbolsSet = new Set();

        if (walletData.all_metrics?.most_prof_symbol) {
            symbolsSet.add(walletData.all_metrics.most_prof_symbol);
        }

        if (walletData.symbol_wise_metrics) {
            walletData.symbol_wise_metrics.forEach(metric => {
                if (metric.Symbol) {
                    symbolsSet.add(metric.Symbol);
                }
            });
        }

        if (walletData.balances?.spot_balances) {
            Object.keys(walletData.balances.spot_balances).forEach(symbol => {
                symbolsSet.add(symbol);
            });
        }

        if (walletData.balances?.positions) {
            walletData.balances.positions.forEach(position => {
                if (position.Coin) {
                    symbolsSet.add(position.Coin);
                }
            });
        }

        return Array.from(symbolsSet);
    };

    const fetchTokenNames = async (symbols) => {
        try {
            const response = await fetch(`${base_api}/get_token_names`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ references: symbols }),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch token names. Status: ${response.status}`);
            }

            const result = await response.json();
            return result.data;
        } catch (error) {
            console.error('Error fetching token names:', error);
            throw error;
        }
    };

    const replaceSymbolsInWalletData = (walletData, tokenNameMap) => {
        const newData = JSON.parse(JSON.stringify(walletData)); // Deep clone

        // Replace in all_metrics
        if (newData.all_metrics?.most_prof_symbol) {
            newData.all_metrics.most_prof_symbol = tokenNameMap[newData.all_metrics.most_prof_symbol] || newData.all_metrics.most_prof_symbol;
        }

        // Replace in symbol_wise_metrics
        if (newData.symbol_wise_metrics) {
            newData.symbol_wise_metrics = newData.symbol_wise_metrics.map(metric => ({
                ...metric,
                Symbol: tokenNameMap[metric.Symbol] || metric.Symbol
            }));
        }

        // Replace in spot balances
        if (newData.balances?.spot_balances) {
            const newBalances = {};
            Object.entries(newData.balances.spot_balances).forEach(([symbol, value]) => {
                const newSymbol = tokenNameMap[symbol] || symbol;
                newBalances[newSymbol] = value;
            });
            newData.balances.spot_balances = newBalances;
        }

        // Replace in positions
        if (newData.balances?.positions) {
            newData.balances.positions = newData.balances.positions.map(position => ({
                ...position,
                Coin: tokenNameMap[position.Coin] || position.Coin
            }));
        }

        return newData;
    };

    // Modified fetch function
    const fetchWalletData = async (address) => {
        setWalletData(null);
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ address: address }),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch wallet data. Status: ${response.status}`);
            }

            const data = await response.json();

            // Get unique symbols and fetch their actual names
            const uniqueSymbols = extractUniqueSymbols(data);
            const tokenNameMap = await fetchTokenNames(uniqueSymbols);

            // Replace symbols with actual names throughout the data

            const processedData = replaceSymbolsInWalletData(data, tokenNameMap);

            setWalletData(processedData);
        } catch (err) {
            setError(err.message);
            setWalletData(null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (walletAddress) {
            navigate(`/portfolio?address=${walletAddress}`);
        }
    };

    return (
        <div >
            {!walletData &&
                <div className={!isLoading && !error ? "wallet_search_container" : "wallet_search_container_2"}>
                    < form onSubmit={handleSubmit} className="">
                        <input
                            type="text"
                            value={walletAddress}
                            onChange={(e) => setWalletAddress(e.target.value)}
                            placeholder="Enter wallet address"
                            className="modern-input"
                        />
                        <button type="submit" className="analyze_button" disabled={isLoading}>
                            {isLoading ? 'Analyzing...' : 'Analyze'}
                        </button>
                    </form>
                </div>
            }

            {
                isLoading && (
                    <div className="loader_card">
                        <div className="loader-container">
                            <Loader2
                                size={64}
                                strokeWidth={1}
                                absoluteStrokeWidth={false}
                                className="animate-spin"
                            />
                        </div>
                        <span className="">Loading wallet data...</span>
                    </div>
                )
            }

            {
                error && (
                    <div className="error_card">
                        Error: {error}
                    </div>
                )
            }

            {
                walletData && (
                    <div className="dashBoard_head">
                        {/* <h1 className="dashBoard_heading">Hyperliquid Trading Metrics</h1> */}
                        <div>
                            <h3 className="wallet_add">
                                <span className="">Wallet Address: </span>
                                <span className="">{walletAddress}</span>
                            </h3>
                        </div>
                        <div className="wallet_note">
                            <h5>
                                We show only realized PnL with assumption of 2 bps fee per trade. Analytics are based on the last 10,000 transactions or all if fewer.
                            </h5>
                        </div>
                    </div>
                )
            }

            {walletData && <Dashboard walletData={walletData} />}
        </div >
    );
};

export default Portfolio;